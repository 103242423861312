import React from "react";

const Onboarding = () => {
    return (
        <div>
            Welcome to CloudSafari! This is your dashboard, all important information will be here.
        </div>
    )
}

export default Onboarding;